
import { Component, Vue, Watch } from 'nuxt-property-decorator'
import carTaxCard from '@pp-frontend/car-tax-widget/components/carTaxCard.vue'
import LazyHydrate from 'vue-lazy-hydration'
import SearchForm from '~/components/SearchForm.vue'
import LicencePlateInput from '~/components/LicencePlateInput.vue'
import RegionList from '~/components/RegionList.vue'
import NewsList from '~/components/index/NewsList.vue'
import OfficialSource from '~/components/index/OfficialSource.vue'
import Reviews from '~/components/index/Reviews.vue'
import Articles from '~/components/index/Articles.vue'
import NewBanner from '~/components/NewBanner.vue'
import ServicesForOfficial from '~/components/index/ServicesForOfficial.vue'
import InfoAccordion from '~/components/index/InfoAccordion.vue'
import StatInfo from '~/components/index/StatInfo.vue'
import InfoAboutService from '~/components/index/InfoAboutService.vue'
import FnsIndexWidgets from '~/components/index/FnsIndexWidgets.vue'
import PushNotify from '~/components/index/PushNotify.vue'
import AccrualExample from '~/components/index/AccrualExample.vue'
import HowToUseForm from '~/components/index/HowToUseForm.vue'
import ScrollToTopButton from '~/components/UI/ScrollToTopButton.vue'

@Component({
  components: {
  carTaxCard,
  FnsIndexWidgets,
  Articles,
  Reviews,
  OfficialSource,
  LazyHydrate,
  NewsList,
  RegionList,
  LicencePlateInput,
  SearchForm,
  NewBanner,
  ServicesForOfficial,
  InfoAccordion,
  InfoAboutService,
  StatInfo,
  PushNotify,
  AccrualExample,
  HowToUseForm,
  ScrollToTopButton
  },
  middleware: 'index-static-middleware'
  })
export default class extends Vue {
  popupComponent: any = () => import('~/components/Popup.vue')
  licence: string = ''
  allowServices = this.$allowServices()

  head () {
    return {
      title: 'Проверка и оплата штрафов ГИБДД онлайн со скидкой 25% на сайте',
      meta: [
        { hid: 'description', name: 'description', content: 'Штрафы ГИБДД: проверка и оплата онлайн по гос номеру автомобиля и СТС или постановлению со скидкой 25% при оплате через сервис. Мгновенный поиск без регистрации. Покажем все актуальные штрафы. Гарантия успешного погашения или возврат денег' }
      ],
      link: [
        { rel: 'canonical', href: 'https://paygibdd.ru/' },
        { rel: 'prefetch', as: 'image', href: require('~/assets/img/popup/ic_outline-star-outline.svg') }
      ]
    }
  }

  beforeMount () {
    /** запись в аналитику */
    window.addEventListener('load', () => {
      this.$axios.get(process.env.API_ENDPOINT + `user/event?event_action=main&ci=${this.$route.query.ci}&gclid=${this.$route.query.gclid}`)
    }, { once: true })
  }

  async mounted () {
    if (this.$route.query.email) {
      await this.$axios.patch(process.env.API_ENDPOINT + 'user', { email: this.$route.query.email })
    }

    await this.$store.dispatch('user/getUser').catch(() => {})
  }

  async activated () {
    await this.$nextTick(() => {
      if (this.$route.hash) {
        // @ts-ignore
        const element = document.getElementById('custom-index')
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    })
  }
}
