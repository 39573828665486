import { render, staticRenderFns } from "./NewBanner.vue?vue&type=template&id=30bb6376&scoped=true&functional=true"
import script from "./NewBanner.vue?vue&type=script&lang=ts"
export * from "./NewBanner.vue?vue&type=script&lang=ts"
import style0 from "./NewBanner.vue?vue&type=style&index=0&id=30bb6376&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "30bb6376",
  null
  
)

export default component.exports